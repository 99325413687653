.media-item__toggle {
  left: 50%;
  top: 50%;
  transition: opacity 0.2s, visibility 0.2s;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;

  & svg g {
    fill: #fff;
  }

  &.is-hidden {
    opacity: 0;
    visibility: hidden;
  }
}


.media-item__poster {
  transition: opacity 0.2s, visibility 0.2s;

  &.is-hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.media-item__video-inner {
  @media (max-width: 767px) {
    padding-bottom: 24px;
  }

  @screen md {
    height: calc(100vh - 24px);
    padding-bottom: 0;
  }

  @screen lg {
    height: calc(100vh - 30px);
  }
}

.media-item__video::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  content: '';
  background: theme(colors.green);
  height: 24px;

  @screen lg {
    height: 30px;
  }
}
