@import "swiper/dist/css/swiper";

.swiper-container {}
.swiper-wrapper {}
.swiper-slide {}
.swiper-pagination {}
.swiper-button-prev {}
.swiper-button-next {}
.swiper-scrollbar {}

.swiper-pagination-bullet {
  cursor: pointer;
}
