.cc-window {
	opacity: 1;
	transition: opacity 1s ease
}

.cc-window.cc-invisible {
	opacity: 0
}

.cc-animate.cc-revoke {
	transition: transform 1s ease;
}

.cc-animate.cc-revoke.cc-top {
	transform: translateY(-2em)
}

.cc-animate.cc-revoke.cc-bottom {
	transform: translateY(2em)
}

.cc-animate.cc-revoke.cc-active.cc-top {
	transform: translateY(0)
}

.cc-animate.cc-revoke.cc-active.cc-bottom {
	transform: translateY(0)
}

.cc-revoke:hover {
	transform: translateY(0)
}

.cc-grower {
	max-height: 0;
	overflow: hidden;
	transition: max-height 1s
}

.cc-revoke,
.cc-window {
	position: fixed;
	overflow: hidden;
	display: flex;
	flex-wrap: nowrap;
	z-index: 9999
}

.cc-window.cc-static {
	position: static
}

.cc-window.cc-floating {
	padding: 2em;
	max-width: 24em;
	flex-direction: column
}

.cc-window.cc-banner {
	padding: 1em 1.8em;
	width: 100%;
	flex-direction: row
}

.cc-revoke {
	padding: .5em
}

.cc-revoke:hover {
	text-decoration: underline
}

.cc-header {
	font-size: 18px;
	font-weight: 700
}

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
	cursor: pointer
}

.cc-link {
	opacity: .8;
	display: inline-block;
	padding: .2em;
	text-decoration: underline
}

.cc-link:hover {
	opacity: 1
}

.cc-link:active,
.cc-link:visited {
	color: initial
}

.cc-btn {
	display: block;
	padding: .4em .8em;
	font-size: .9em;
	font-weight: 700;
	border-width: 2px;
	border-style: solid;
	text-align: center;
	white-space: nowrap
}

.cc-highlight .cc-btn:first-child {
	background-color: transparent;
	border-color: transparent
}

.cc-highlight .cc-btn:first-child:focus,
.cc-highlight .cc-btn:first-child:hover {
	background-color: transparent;
	text-decoration: underline
}

.cc-close {
	display: block;
	position: absolute;
	top: .5em;
	right: .5em;
	font-size: 1.6em;
	opacity: .9;
	line-height: .75
}

.cc-close:focus,
.cc-close:hover {
	opacity: 1
}

.cc-revoke.cc-top {
	top: 0;
	left: 3em;
}

.cc-revoke.cc-bottom {
	bottom: 0;
	left: 3em;
}

.cc-revoke.cc-left {
	left: 3em;
	right: unset
}

.cc-revoke.cc-right {
	right: 3em;
	left: unset
}

.cc-top {
	top: 1em
}

.cc-left {
	left: 1em
}

.cc-right {
	right: 1em
}

.cc-bottom {
	bottom: 1em
}

.cc-floating>.cc-link {
	margin-bottom: 1em
}

.cc-floating .cc-message {
	display: block;
	margin-bottom: 1em
}

.cc-window.cc-floating .cc-compliance {
	flex: 1 0 auto
}

.cc-window.cc-banner {
	align-items: center
}

.cc-banner.cc-top {
	left: 0;
	right: 0;
	top: 0
}

.cc-banner.cc-bottom {
	left: 0;
	right: 0;
	bottom: 0
}

.cc-banner .cc-message {
	display: block;
	flex: 1 1 auto;
	max-width: 100%;
	margin-right: 1em
}

.cc-compliance {
	display: flex;
	align-items: center;
	align-content: space-between
}

.cc-floating .cc-compliance>.cc-btn {
	flex: 1
}

.cc-btn+.cc-btn {
	margin-left: .5em
}

@media print {
	.cc-revoke,
	.cc-window {
		display: none
	}
}

@media screen and (max-width:900px) {
	.cc-btn {
		white-space: normal
	}
}

@media screen and (max-width:414px) and (orientation:portrait),
screen and (max-width:736px) and (orientation:landscape) {
	.cc-window.cc-top {
		top: 0
	}
	.cc-window.cc-bottom {
		bottom: 0
	}
	.cc-window.cc-banner,
	.cc-window.cc-floating,
	.cc-window.cc-left,
	.cc-window.cc-right {
		left: 0;
		right: 0
	}
	.cc-window.cc-banner {
		flex-direction: column
	}
	.cc-window.cc-banner .cc-compliance {
		flex: 1 1 auto
	}
	.cc-window.cc-floating {
		max-width: none
	}
	.cc-window .cc-message {
		margin-bottom: 1em
	}
	.cc-window.cc-banner {
		align-items: unset
	}
	.cc-window.cc-banner .cc-message {
		margin-right: 0
	}
}

.cc-floating.cc-theme-classic {
	padding: 1.2em;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
	text-align: center;
	display: inline;
	flex: none
}

.cc-theme-classic .cc-btn:last-child {
	min-width: 140px
}

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
	display: inline-block
}


/* custom theme */

.cc-revoke { display: none; }

.cc-window {
  background: theme('colors.lightgray');
	color: theme('colors.darkgray');
}

.cc-window.cc-banner {
	padding: 2rem;

	@screen lg {
		padding: 2rem 3rem;
	}
}

.cc-message {
	font-size: 1.2rem;
}

.cc-compliance {
	justify-content: center;
}

.cc-btn.cc-allow {
  background: theme('colors.red');
  color: theme('colors.white');
  border-color: theme('colors.red');

  &:hover,
  &:focus {
    color: theme('colors.red');
    background: theme('colors.white');
    border-color: theme('colors.white');
  }
}
