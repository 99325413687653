.testimonials .swiper-button {
  transform: translateY(8%);
  bottom: 0;
  top: unset;
}

.testimonials .swiper-button-prev {
  left: -0.6rem;
}

.testimonials .swiper-button-next {
  right: -0.6rem;
}

.testimonials .swiper-container {
  padding-bottom: 8rem;
}

.testimonials_citation:before{
  content: '“';
  @apply text-red;
  @apply text-xxl;
  @apply absolute;
  font-style: normal;
  top: -2rem;
  left: -3.5rem;
}

.testimonials .swiper-pagination-bullet:last-child {
  margin-right: 0 !important;
}

@screen lg {

  .testimonials_citation:before{
    content: '“';
    @apply text-red;
    @apply text-xxl;
    @apply absolute;
    font-style: normal;
    top: -2rem;
    left: -4rem;
  }

  .testimonials .swiper-pagination {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 0;
  }

  .testimonials .swiper-container {
    margin: 0 13.5rem;
    padding-bottom: 7rem;
  }

  .testimonials .swiper-slide {
    width: 34.2rem !important;
  }

  .testimonials .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

  .testimonials .swiper-button {
    top: 50%;
    transform: translateY(-100%);
  }
}

@screen xl {
  .testimonials .swiper-slide {
    width: 47rem !important;
  }
}
