@tailwind base;

/* @font-face {
  font-family: Caecilia;
  font-weight: normal;
  src: url(../assets/fonts/caecilia-regular.woff) format("woff");
}
@font-face {
  font-family: Caecilia;
  font-weight: bold;
  src: url(../assets/fonts/caecilia-bold.woff) format("woff");
} */

@tailwind components;

@tailwind utilities;
