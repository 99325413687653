@import "magnific-popup/dist/magnific-popup.css";

.mfp-menu.mfp-bg,
.mfp-menu.mfp-wrap {
  position: fixed !important;
  top: 8rem !important;
}

.mfp-menu.mfp-wrap {
  /* height: calc(100vh - 68px) !important; */
  bottom: 0 !important;
  height: auto !important;
}

.mfp-menu.mfp-bg {
  opacity: 1;
  height: 0;
  transition: all 0.15s ease-out;
  @apply bg-white;
}

.mfp-menu.mfp-bg.mfp-ready {
  opacity: 1;
  height: calc(100vh - 80px);
}

.mfp-menu.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-menu.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-menu.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-menu.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-menu .mfp-content {
  display: flex;
  width: 100%;
  justify-content: center;
  min-height: 100%;
}

.mfp-menu .menu li {
  opacity: 0;
  transition: opacity 0.1s;
}

.mfp-menu.mfp-ready .menu li{
  opacity: 1;
  transition-duration: 0.3s;
  transition-delay: 0.1s;
}

.mfp-menu .menu-locales {
  opacity: 0;
  transition: opacity 0.1s;
}

.mfp-menu.mfp-ready .menu-locales {
  opacity: 1;
  transition-duration: 0.3s;
  transition-delay: 0.1s;
}

.mfp-menu .mfp-container {
  text-align: left;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mfp-menu .mfp-container {
  @apply px-8;
}

.mfp-menu .mfp-container::before {
  @apply hidden;
}

/* @screen lg {
  .mfp-menu .mfp-container {
    padding-left: 40px;
    padding-right: 40px;
  }
} */
