.breadcrumb li:not(:last-child):after {
  content: '';
  width: 0.6rem;
  height: 0.6rem;
  top: 50%;
  right: -0.4rem;
  transform: translateY(-50%);
  @apply absolute;
  @apply bg-darkgray;
  @apply rounded-full;
}