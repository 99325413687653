.collaborator_citation p:before{
    content: '“';
    @apply text-red;
    @apply text-xxl;
    @apply absolute;
    font-style: normal;
    top: -2rem;
    left: 0;
}

@screen lg {
  .collaborator_citation p:before {
    top: -1.6rem;
    left: 0;
  }
}
