.menu-locales {
  & li {
    @apply mr-4;

    &:lastchild {
      @apply mr-0;
    }
  }

  & .current-lang {
    @apply text-red;
  }
}
