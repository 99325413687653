.hamburger {
  position: absolute;
  top: 3rem !important;
  right: theme(container.padding);
  width: 32px;
  height: 22px;
  background: transparent;
  opacity: 1 !important;
  transition: opacity 0.3s;
  transition-delay: 1.2s;
  outline: 0 !important;
}

.hamburger-box {
  width: 32px;
  height: 22px;
  display: inline-block;
  position: relative;
}


@screen lg {
  .hamburger {
    @apply hidden;
  }
}

.is-menu-open {
  overflow: hidden;
	-webkit-overflow-scrolling: auto;
	width: 100%;
	position: fixed;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  @apply absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 32px;
  @apply bg-red;
  @apply block;
}

.hamburger-inner::before {
  top: 11px;
}

.hamburger-inner::after {
  top: 22px;
}

.hamburger--close.is-active .hamburger-inner {
  background: transparent;
}

.hamburger--close.is-active .hamburger-inner::before,
.hamburger--close.is-active .hamburger-inner::after {
  top: 11px;
  @apply bg-red;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
}

.hamburger--close.is-active .hamburger-inner::after {
  transform: rotate(-45deg);
}
