.badge {
  @apply inline-flex items-center rounded-full border border-red text-red font-semibold text-xs bg-lightgray h-12 mr-5 mb-4;
}

.badge__icon {
  width: 2.8rem;
  height: 2.8rem;
  margin: 0.1rem -0.8rem 0 0.6rem;

  & svg {
    @apply w-full h-auto;
  }
}

.badge__text {
  margin: .1rem 1.5rem 0 1.5rem;
}