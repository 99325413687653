.front-page-header {

  & .item {
    flex-grow: 0;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-right: 9px;
    margin-bottom: 11px;
    transition: opacity 0.6s, transform 0.1s;
    will-change: opacity, transform;

    @screen lg {
      margin-right: 11px;
      margin-bottom: 10px;
    }

    &:hover {
      transform: scale(1.3);
    }
  }

  & .item-0 {
    background-image: url('../assets/images/animals/bear.svg');
  }
  & .item-1 {
    background-image: url('../assets/images/animals/bee.svg');
  }
  & .item-2 {
    background-image: url('../assets/images/animals/cow.svg');
  }
  & .item-3 {
    background-image: url('../assets/images/animals/crab.svg');
  }
  & .item-4 {
    background-image: url('../assets/images/animals/dog.svg');
  }
  & .item-5 {
    background-image: url('../assets/images/animals/elephant.svg');
  }
  & .item-6 {
    background-image: url('../assets/images/animals/girafe.svg');
  }
  & .item-7 {
    background-image: url('../assets/images/animals/hedgedog.svg');
  }
  & .item-8 {
    background-image: url('../assets/images/animals/horse.svg');
  }

  & .item-out {
    opacity: 0;
  }
}

.front-page-header__icons {
  position: absolute;
  top: 0;
  left: 2rem;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
