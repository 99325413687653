.swiper-button {
  width: 10px;
  height: 18px;
  margin: 0;
}

.swiper-button-next {
  background: url('../assets/images/icons/arrow-next-mobile.svg') no-repeat center center;
  right: 1rem;
  left: auto;
  transform: translateY(-100%);
}

.swiper-button-prev {
  background: url('../assets/images/icons/arrow-prev-mobile.svg') no-repeat center center;
  left: 1rem;
  right: auto;
  transform: translateY(-100%);
}

.swiper-container {
  padding-bottom: 5rem;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
  left: 0;
  width: 100%;
}

@screen lg {
  .swiper-button-next {
    background: url('../assets/images/icons/arrow-next.svg') no-repeat center center;
    right: 5rem;
    transform: translateY(-50%);
  }

  .swiper-button-prev {
    background: url('../assets/images/icons/arrow-prev.svg') no-repeat center center;
    left: 5rem;
    transform: translateY(-50%);
  }

  .swiper-container {
    padding-bottom: 0;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 1rem;
  }
}

.swiper-pagination-bullet {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  border-radius: 100%;
  background-color: #616365;
  opacity: 1 !important;
  margin-right: 1.5rem !important;
  margin-left: 0 !important;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #D9102A !important;
}
