/* Only hide inner on mobile/tablet and only if js is not enabled.  */

.js .nav-main__inner {
  display: none;
}

.nav-main__inner .is-active {
  display: block;
}

.mfp-content .nav-main__inner {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  /* min-height: calc(100vh - 68px); */
  min-height: 100% !important;
  text-align: left;
  padding-bottom: 6px;
}

@screen lg {

  .js .nav-main__inner {
    display: flex;
  }

}
