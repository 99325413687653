.gform_wrapper,
.hbspt-form {
  @apply relative;
}

.gform_anchor {
  @apply absolute;
  top: -20rem;
  @screen md {
    top: -16rem;
  }
}

.gfield_label,
.hs-form-field > label {
  @apply mb-3 font-bold lowercase block;
}

.gfield_required {
  @apply text-red;
  margin-left: 4px;
}

.gform_fields .gfield,
.hs-form-field {
  @apply mb-8;
}

.gform_fields,
.hs-form-field {
  & input[type=text],
  & input[type=email],
  & input[type=number],
  & input[type=tel],
  & textarea {
    @apply border border-solid border-gray w-full p-6 leading-tight;

    &::placeholder, {
      color: #909293 !important;
    }
  }

  & .gfield_select,
  & .hs-fieldtype-select select {
    @apply bg-transparent;
    color: #909293 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../assets/images/icons/arrow-down.svg') no-repeat 97.5%;
    @apply w-full;
  }
}

.gfield--checkbox,
.gfield--consent,
.gfield--radio {

  & .ginput_container li {
    @apply mb-4;
    &:last-child {
      @apply mb-0;
    }
  }

  & input {
    display: none;

    & + label {
      display: block;
      position: relative;
      padding-left: 3rem;

      &:before {
        content: "";
        width: 1.8rem;
        height: 1.8rem;
        @apply absolute block bg-white border border-gray top-0 left-0;
      }
    }

    & + label:after {
      content: "";
      left: .4rem;
      top: .4rem;
      width: 1rem;
      height: 1rem;
      transition: opacity .3s;
      @apply absolute block bg-gray opacity-0;
    }

    &:checked + label:after {
      opacity: 1;
    }
  }
}

.gfield--radio {
  & input + label:before {
    @apply rounded-full;
  }
  & input + label:after {
    @apply rounded-full;
  }
}

.hs-fieldtype-radio,
.hs-fieldtype-checkbox,
.hs-fieldtype-booleancheckbox {

  & .inputs-list li {
    @apply mb-4;
    &:last-child {
      @apply mb-0;
    }

    & > label {
      @apply relative block text-gray uppercase cursor-pointer;

      & span {
        @apply relative inline-block pl-12;

        &::before {
          content: "";
          width: 1.8rem;
          height: 1.8rem;
          @apply absolute block bg-white border border-gray top-0 left-0;
        }

        &::after {
          content: "";
          left: .4rem;
          top: .4rem;
          width: 1rem;
          height: 1rem;
          transition: opacity .3s;
          @apply absolute block bg-gray opacity-0;
        }
      }

      & input {
        display: none;

        &:checked + span::after {
          @apply opacity-100;
        }
      }
    }
  }

  & .hs-form-radio > label span::after,
  & .hs-form-radio > label span::before {
    @apply rounded-full;
  }
}

.validation_error,
.hs-error-msg {
  @apply text-s text-red mb-10;
}

.gfield_error .ginput_container.ginput_container_text,
.gfield_error .ginput_container_email,
.gfield_error .medium.gfield_select,
.gfield_error .ginput_container_textarea,
.hs-form-field .hs-input.error {
  @apply border-red;
}

.gfield_description,
.hs-richtext {
  @apply text-xs mt-4;
}

.gfield_description.validation_message,
.hs_error_rollup {
  @apply text-red;
}

.hs_error_rollup {
  @apply mt-8;
}

.gform_confirmation_message {
  @apply text-base text-white bg-green p-8;
}

.gfield_consent_label a {
  @apply underline;
}

.hs-submit {
  @apply mt-8;
}
