.collaborator__quote {
  padding: 1.2rem 0 0 4.8rem;
  font-size: 1.8rem;

  &:before{
    content: '“';
    @apply absolute text-red;
    font-size: 4.8rem;
    font-style: normal;
    top: 0;
    left: 0;
  }
}