.button,
input[type=submit],
input[type=button] {
  @apply font-bold lowercase inline-flex items-center bg-red text-white py-5 px-5 leading-tight;
  border-radius: 0;
}

.button--white {
  @apply bg-white text-red;
}

.button--grey {
  @apply bg-darkgray;
}

.button--small {
  @apply py-4;
}

.button--outline {
  @apply bg-white border border-red text-red;
}
