 form.search__filters {
    justify-content: space-between;
}

form.search__filters input.hidden {
    margin-right: 3rem;
}

.search__list::-webkit-scrollbar {
    width: 1.5rem;
}


 .search__map {
     height: 250px;
     @screen lg {
         height: 415px;
     }
 }
 
 .search__list {
     @screen lg {
         height: 415px;
         overflow-y: scroll;
     }
 }
 
 @media only screen and (max-width: 768px) {
     .search__list {
         height: 415px !important;
         overflow-y: scroll!important;
     }
 }
 
 .search__marker {
     width: 24px;
     height: auto;
 }
 
 .search__listing {
     @nest .js & {
         opacity: 0;
         transition: opacity 0.3s;
         &.is-ready {
             opacity: 1;
         }
     }
 }
 
 .search-item__marker {
     width: 36px;
     height: 36px;
     background-image: url('../assets/images/map/pin.svg');
     background-size: 36px 36px;
 }
 
 .search-item.is-active {
     & .search-item__marker {
         background-image: url('../assets/images/map/pin-active.svg');
     }
 }