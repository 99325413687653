.text h3 {
  @apply mb-10 font-bold text-red text-m;
}

.text h4 {
  @apply mb-20 font-bold text-m;
}

.text p {
  @apply mb-20;
}

.text strong {
  @apply font-bold;
}

@screen lg {
  .text h3 {
    @apply text-xl;
  }

  .text h4 {
    @apply text-m;
  }
}
