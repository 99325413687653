.card-item:nth-child(n+1) {
  @apply mt-8;
  @apply mb-0;
}

.card-item:last-child {
  @apply border-none;
}


.card-item:last-child .card-value {
  @apply mb-0;
}

@screen lg {
  .card-item:nth-child(n+1) {
    @apply mt-8;
    @apply mb-0;
  }
}

.card-row {
  @apply border-b border-gray border-dashed py-3;
}
