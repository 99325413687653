.post-password-form {
  @apply py-40;
}

.password-protected p {
  @apply text-base lowercase;
}

.post-password-form p:last-child {
  @apply mt-12;
}

.password-protected label {
  @apply block leading-normal lowercase text-base font-bold text-red;
}

.password-protected input[type=password] {
  @apply border border-solid border-gray mt-6 p-6;
  height: 5.7rem;
}

.password-protected input[type=submit] {
  @apply text-white bg-red lowercase text-base font-bold p-6 inline-block cursor-pointer mt-8 ml-0;
}

@screen md {
  .password-protected p {
    @apply text-m;
  }

  .password-protected label {
    @apply mt-0 text-m;
  }

  .password-protected input[type=password] {
    @apply mt-0;
  }

  .post-password-form p:first-child {
    width: 90%;
    @apply mx-auto;
  }

  .post-password-form p:last-child {
    @apply flex mx-auto mt-12;
    width: fit-content;
  }

  .password-protected input[type=submit] {
    @apply ml-8 mt-0;
  }
}

@screen lg {
  .post-password-form p:first-child {
    width: 50%;
  }
}

