.menu-social {

  & li {

    & a {
      background-repeat: no-repeat;
      background-size: 20px 20px;
      padding-left: 36px;
    }
  }

  & .facebook a {
    background-image: url('../assets/images/facebook.svg');
  }

  & .instagram a {
    background-image: url('../assets/images/instagram.svg');
  }

  & .twitter a {
    background-image: url('../assets/images/twitter.svg');
  }
}
