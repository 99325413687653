.site-footer .current_page_item a,
.site-footer .current-menu-item a {
  @apply text-white;
}

.site-footer .current-lang a {
  @apply text-white;
}

.site-footer .menu {
  @apply flex-wrap;
}

.site-footer .menu li:nth-child(-n+4) {
  @apply mb-8;
}
