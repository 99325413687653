.menu li:last-child {
  @apply mr-0;
}

.current_page_item a,
.current-menu-item a {
  @apply text-red;
}

.mfp-content .menu {
  @apply my-20;
}

.mfp-content .menu li {
  @apply mb-16;
  @apply text-darkgray;
}

.mfp-content .menu li:last-child {
  @apply mb-0;
}

.mfp-content .menu-locales li {
  @apply text-darkgray;
}

.mfp-content .menu-locales .current-lang {
  @apply text-red;
}
