/* @import "./base/checkbox.css"; */

/* purgecss start ignore */

@import "./components/badge/badge.css";
@import "./components/breadcrumb/breadcrumb.css";
@import "./components/button/button.css";
@import "./components/collaborator/collaborator.css";
@import "./components/detail-item/detail-item.css";
@import "./components/lightbox/lightbox.css";
@import "./components/media-item/media-item.css";
@import "./components/nav-main/nav-main.css";
@import "./components/menu/menu.css";
@import "./components/menu-locales/menu-locales.css";
@import "./components/menu-social/menu-social.css";
@import "./components/menu-toggle/menu-toggle.css";
@import "./components/slideshow/slideshow.css";
@import "./components/site-header/site-header.css";
@import "./components/site-footer/site-footer.css";
@import "./components/icon-circular/icon-circular.css";
@import "./components/card/card-logos.css";
@import "./components/card/card-features.css";
@import "./components/gform/gform.css";
@import "./components/pagination/pagination.css";
@import "./components/select/select.css";
@import "./components/teaser-facility/teaser-facility.css";
@import "./components/mini-header/mini-header.css";

@import "./components/cookieconsent/cookieconsent.css";

/*
 *  Modules
 */

@import "./modules/Collaborator/Collaborator.css";
@import "./modules/FrontPageHeader/FrontPageHeader.css";
@import "./modules/Slideshow/Slideshow.css";
@import "./modules/Testimonials/Testimonials.css";
@import "./modules/Text/Text.css";
@import "./modules/Search/Search.css";


/*
 *  Views
 */
@import "./views/password-protected/password-protected.css";



@import "./utils/tailwind.css";

/*
 *  Misc
 */


html {
  font-size: 62.5%; /* To make 1rem = 10px */
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-content {
  margin-top: 8.2rem;
}

@screen lg {
  .site-content {
    margin-top: 12.2rem;
  }
}

.heading-1,
.heading-2,
.heading-3 {
  @apply leading-tight font-bold text-red lowercase;
}

.heading-1 {
  @apply text-xl mb-20;
  @screen lg {
    @apply text-huge;
  }
}

.heading-2 {
  @apply text-l mb-12;
  @screen lg {
    @apply text-xl;
  }
}

.heading-3 {
  @apply text-m text-darkgray mb-12;
}


.rich-text {
  @apply font-normal overflow-hidden;

  & :first-child {
    @apply mt-0;
  }

  & p {
    @apply mb-8;
  }

  & p:last-child {
    @apply mb-0;
  }

  & ul,
  & ol {
    @apply mb-8;
  }

  & ul:last-child,
  & ol:last-child {
    @apply mb-0;
  }

  & li {
    @apply ml-6 mb-6 pl-6 list-disc;
  }

  & li:last-child {
    @apply mb-0;
  }

  & h3,
  & h4 {
    @apply text-red text-l lowercase font-semibold mt-16 mb-8;
  }

  & h4 {
    @apply text-darkgray;
  }

  & h5,
  & h6 {
    @apply text-red text-m lowercase font-semibold mt-12 mb-10;
  }

  & h6 {
    @apply text-darkgray;
  }

  & a:not(.button) {
    @apply text-red underline;
  }

  & strong {
    @apply font-bold;
  }

  & .alignright {
    float: right;
    margin: 0.5em 0 0.5em 1em;
  }

  & .alignleft {
    float: left;
    margin: 0.5em 1em 0.5em 0;
  }

  & .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #909293 !important;
  @apply rounded-none border border-solid border-gray bg-white mt-4 w-full;
  padding: .8rem;
  background: url('../assets/images/icons/arrow-down.svg') no-repeat 95%;
}

[data-archive-job-content] {
  transition: opacity 0.2s;
}

[data-archive-job-content].is-loading {
  opacity: 0.2;
}

.swup-transition-fade {
  transition: 0.4s;
  opacity: 1;
}
html.is-animating .swup-transition-fade {
  opacity: 0;
}
html.is-rendering .swup-transition-fade.delay-1 { transition-delay: .1s; }
html.is-rendering .swup-transition-fade.delay-2 { transition-delay: .2s; }
html.is-rendering .swup-transition-fade.delay-3 { transition-delay: .3s; }
html.is-rendering .swup-transition-fade.delay-4 { transition-delay: .4s; }
html.is-rendering .swup-transition-fade.delay-5 { transition-delay: .5s; }

/* purgecss end ignore */
